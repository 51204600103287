import React from "react"
import Helmet from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"
import {Location} from '@reach/router';
import {getOpenGraphMetadata, getTwitterMetadata, getLocaleLinks} from '../helpers/metadataHelpers';
import PropTypes from 'prop-types';
// const translations = require("../utils/translations");
import {useIntl} from 'gatsby-plugin-react-intl'
import useSiteMetadata from "../helpers/hooks/use-site-metadata";

// const languageMetadata = translations.languageMetadata;

const siteMetadataQuery = graphql`
  query {
    file(relativePath: {eq: "logo-square.svg"}) {
      publicURL
    }
  }
`;

export const SEO = ({description, lang = 'en', locales = [],  meta = [], title, image}) => {
  const {file} = useStaticQuery(siteMetadataQuery);

  const intl = useIntl().formatMessage;
  const site = useSiteMetadata();

  const descriptionLocale = {
    en: site.descriptions.en,
    nl: site.descriptions.nl
  };

  const localesArray = [
    {
      "lang" : "en",
      "url" : locales.en
    },
    {
      "lang" : "nl",
      "url" :  locales.nl
    },
  ];

  const metaDescription = description || descriptionLocale[intl({ id: 'lang' })];
  const metaImage = image != null ? image.childImageSharp.fluid.src : file.publicURL;
  const metaTitle = (title && `${title} | ${site.title}`) || site.title;

  return (
    <Location>
      {({location}) => (
        <Helmet
          htmlAttributes={{lang}}
          title={metaTitle}
          // titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {name: `description`, content: metaDescription},
            ...getOpenGraphMetadata(site, metaTitle, lang, metaDescription, location, metaImage),
            ...getTwitterMetadata(site, metaTitle, metaDescription, metaImage),
            ...meta,
          ]}
          link={[
            {rel: `alternate`, type: `application/rss+xml`, title: `Feed`, href: `${process.env.SITE_URL}/rss.xml`},
            {rel: `icon`, href: file.publicURL},
            {name: 'alternate', href: `${process.env.SITE_URL}${locales.en}`, hrefLang: `x-default`},
            ...getLocaleLinks(localesArray)
          ]}>
        </Helmet>
      )}
    </Location>
  )
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  locales: PropTypes.object,
  image: PropTypes.shape({
    fluid: PropTypes.object,
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string.isRequired
      })
    })
  }),
  meta: PropTypes.array
};
