const translations = require("../utils/translations");
const languageMetadata = translations.languageMetadata;

export function getTagMetadata(tags = []) {
  return tags != null ? tags.map(tag => ({name: 'article:tag', content: tag})) : [];
}

export function getSectionMetadata(categories = []) {
  return categories != null ? categories.map(category => ({name: 'article:section', content: category})) : [];
}

export function getLocaleLinks (Locales) {
    return Locales != null ? Locales.map(locale => ({ name: 'alternate', href: `${process.env.SITE_URL}${locale.url}`, hrefLang: languageMetadata[locale.lang]['hreflang'] })) : [];
}

export function getTimeMetadata(publishedAt, modifiedAt) {
  return [
    {name: `og:updated_time`, content: modifiedAt},
    {name: `article:published_time`, content: publishedAt},
    {name: `article:modified_time`, content: modifiedAt}
  ];
}

export function getOpenGraphMetadata(site, locale, title, metaDescription, location, image) {
  return [
    {property: `og:title`, content: title},
    {property: `og:description`, content: metaDescription},
    {property: `og:site_name`, content: site.title},
    {property: `og:type`, content: `website`},
    {property: `og:locale`, content: locale},
    {property: `og:url`, content: `${site.siteUrl}${location.pathname}`},
    {property: `og:image`, content: `${site.siteUrl}${image}`},
    {property: `og:image:secure_url`, content: `${site.siteUrl}${image}`},
  ];
}

export function getTwitterMetadata(site, title, metaDescription, image) {
  return [
    {name: `twitter:card`, content: `summary`},
    {name: `twitter:creator`, content: site.author},
    {name: `twitter:title`, content: title},
    {name: `twitter:description`, content: metaDescription},
    {name: `twitter:site`, content: site.author},
    {name: `twitter:image`, content: `${site.siteUrl}${image}`},
  ];
}
