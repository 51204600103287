import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import {SEO} from './seo';
import useSiteMetadata from '../helpers/hooks/use-site-metadata';

const Redirect = () => {
  const intl = useIntl().formatMessage
  const data = useSiteMetadata()

  const descriptionLocale = {
    en: data.descriptions.en,
    nl: data.descriptions.nl
  };

  const metaDescription = descriptionLocale[intl({ id: 'lang' })]

  return <SEO image={null} description={metaDescription} lang={intl({ id: 'lang' })} />
}

export default Redirect
