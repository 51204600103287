import React from 'react';
import {ParallaxProvider} from 'react-scroll-parallax';

export function onServiceWorkerUpdateReady() {
  console.log('onServiceWorkerUpdateReady');
  window.dispatchEvent(new Event('onServiceWorkerUpdate'));
}

export const wrapPageElement = ({ element, props }) => {
    return <ParallaxProvider {...props}>{element}</ParallaxProvider>;
};
